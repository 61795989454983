import React, { Fragment, useEffect, useState, useRef, lazy } from "react";
import { saveAs } from "file-saver";
import { ReactComponent as BackArrow } from "../../Icons/BorderBackArrow.svg";
import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import { ReactComponent as JPGIcon } from "../../Icons/Jpg.svg";
import { ReactComponent as Close } from "../../Icons/BorderAlertClose.svg";
import { ReactComponent as DeleteIcon } from "../../Icons/DeleteNB.svg";
import { ReactComponent as UploadIcon } from "../../Icons/UploadIcon.svg";
import { ReactComponent as CsvIcon } from "../../Icons/CsvIcon.svg";
import { ReactComponent as PdfIcon } from "../../Icons/PdfIcon.svg";
import {
  PrimaryTypography,
  StyledPrimaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  SecondaryTypography,
  TypographyDefault,
  styledTyphographyDefault,
} from "../../Theme";
import {
  Autocomplete,
  Paper,
  Chip,
  Avatar,
  Box,
  Skeleton,
  Button,
  InputLabel,
  FormControl,
  Card,
  TextField,
  CardContent,
  CardHeader,
  Grid,
  Modal,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import StatusStepper from "./Stepper";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import stringAvatar from "../../CommonComponents/avatar";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../Redux/AxiosInstance";
import { getChip } from "../../Utils";
import AlertModal, { PostLoaderModal } from "../../CommonComponents/modal";
import CircularProgress from "@mui/material/CircularProgress";
// import { MenuItem } from "@mui/base";

const ReviewFaultReport = () => {
  const { LanguageData } = useSelector((state) => state.Language);
  const { id } = useParams();
  const navigate = useNavigate();
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const [tabValue, setTabValue] = useState(0);
  const [updateTabValue, setUpdateTabValue] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [updateAnchorEl, setUpdateAnchorEl] = useState(null);
  const updateOpen = Boolean(updateAnchorEl);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const downloadOpen = Boolean(downloadAnchorEl);
  const [selectedReport, setSelectedReport] = useState({});
  const [reportStatus, setReportStatus] = useState([]);
  const [reporttrack, setReporttrack] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMultiple, setLoadingMultiple] = useState(false);
  const [uploadImage, setUploadImage] = useState([]);
  const [updateStatusData, setUpdateStatusData] = useState({
    status: "label",
    assign_staff: [],
    comment: "",
    staffs_id: [],
  });
  const [errorText, setErrorText] = useState({
    status: "",
    assign_staff: "",
    comment: "",
  });
  const [staffList, setStaffList] = useState([]);
  const [exitUpdateStatusModal, setexitUpdateStatusModal] = useState(false);
  const [confirmUpdateStatusModal, setconfirmUpdateStatusModal] =
    useState(false);

  const [averageResponse, setAverageResponse] = useState(null);
  const [pageloading, setpageloading] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);
  const [loadingReport, setLoadinReport] = useState(false);
  const [loadingMap, setLoadingMap] = useState({});

  const inputImg = useRef();

  const exitUpdateStatusData = {
    label: LanguageData?.Update_Status || "Confirm To Exit Update Status",
    descriptionOne:
      LanguageData?.Update_Status_sub ||
      "Please confirm that you exit updating the status.",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: LanguageData?.Announcement_Cancel || "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: LanguageData?.Announcement_DeleteNow || "Exit Now",
    },
  };

  const getReportSingle = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}fault_report/property/${propertyId}/report/${id}`
      );
      const data = response.data.data.fault_report;

      setSelectedReport({
        id: data.uuid,
        updated_at: data?.updated_at,
        duration: "",
        reported_on: moment.utc(data?.created_at).local(),
        status: data?.status?.id,
        status_key: data?.status?.key,
        closed_on: "",
        avgResponseTime: "",
        assigned_staff: data?.assigned_staff,
        report_details: {
          title: data?.title,
          location: data?.location_details,
          facility: data?.facility_details,
          description: data?.description,
          appointment: "",
        },
        IsReportedBystaff:
          data?.report_track[data?.report_track?.length - 1]?.updated_user
            ?.role,
        reported_by_details: {
          reported_by:
            data?.report_track[data?.report_track?.length - 1]?.updated_user
              .full_name,
          email:
            data?.report_track[data?.report_track?.length - 1]?.updated_user
              .email,
          contact:
            data?.report_track[data?.report_track?.length - 1]?.updated_user
              .country_code + data?.report_track[0]?.updated_user.phone,
          unit: data?.report_track[data?.report_track?.length - 1]?.updated_user
            ?.unit_details?.unit_no,
          dep_role: `${data?.report_track[data?.report_track?.length - 1]?.updated_user
              ?.department?.name
            } • ${data?.report_track[data?.report_track?.length - 1]?.updated_user
              ?.role?.name
            }`,
        },
        images: data?.images,
      });
      setReportStatus(data?.report_track.slice().reverse());
      setReporttrack(data?.report_track);
      setpageloading(false);
    } catch (error) {
      console.log("error :", error);
    }
  };

  useEffect(() => {
    getReportSingle();

    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/staff_onboard`
      )
      .then((response) => {
        const data = response.data.data.staff_onboard;
        const staffs = data.filter((itm) => itm?.status?.id == 1);
        setStaffList(() => {
          const id = staffs.map((itm) => ({
            id: itm?.user_id,
            full_name: itm?.user_details?.personal_details?.full_name,
            name: itm?.user_details?.personal_details?.first_name,
            department: itm?.department_details?.department_name,
            role_name: itm?.role_details?.title,
          }));
          return id;
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  const closeModal = () => {
    setOpenModal(false);
    setUpdateStatusData({
      status: "label",
      assign_staff: [],
      comment: "",
      staffs_id: [],
    });
    setErrorText({ status: "", assign_staff: [], comment: "" });
    setUpdateTabValue(1);
    setexitUpdateStatusModal(false);
    setconfirmUpdateStatusModal(false);
    setUploadImage([]);
  };

  const handleTabValue = (e, value) => {
    setTabValue(value);
  };

  const handleUpdateTabValue = (e, value) => {
    if (valid()) {
      setUpdateTabValue(value);
    }
  };

  const CustomTabPanel = ({ value, index, children }) => {
    return <>{value === index && <Box>{children}</Box>}</>;
  };

  const downloadMultipleImage = async () => {
    const arr = [];
    selectedReport?.images.map((itm) => arr.push(itm.path));
    const data = { file_path_array: arr };
    try {
      setLoadingMultiple(true);
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}download_file`,
        data,
        { responseType: "blob" }
      );
      saveAs(response.data, "report.zip");
    } catch (error) {
      console.error("Error downloading image:", error);
    } finally {
      setLoadingMultiple(false);
    }
  };

  // const downloadImage = async (path) => {
  //     // const imageUrl = `https://lobybe.demopsts.com/storage/fault_report/1695881495_images.jpg`;
  //     // const imageUrl = "https://images.pexels.com/photos/16690873/pexels-photo-16690873/free-photo-of-model-in-a-white-blouse-and-a-black-mini-skirt.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
  //     const filename = path.split("/")[2]
  //     const data = { "file_path": path }
  //     try {
  //         setLoading(true);
  //         const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}download_file`, data, { responseType: "blob" })
  //         saveAs(response.data, filename)
  //     }
  //     catch (error) {
  //         console.error('Error downloading image:', error);
  //     }
  //     finally {
  //         setLoading(false);
  //     }
  // }

  const downloadImage = async (path) => {
    const filename = path.split("/")[2];
    const data = { file_path: path };

    try {
      setLoadingMap((prevLoadingMap) => ({ ...prevLoadingMap, [path]: true }));

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}download_file`,
        data,
        { responseType: "blob" }
      );
      saveAs(response.data, filename);
    } catch (error) {
      console.error("Error downloading image:", error);
    } finally {
      setLoadingMap((prevLoadingMap) => ({ ...prevLoadingMap, [path]: false }));
    }
  };

  // updateStatus

  const handleChange = (e, val) => {
    const { name, value } = e.target;

    if (val !== "") {
      if (val?.length <= 2) {
        const staff_id = [];
        const staffs = val.map((itm) => staff_id.push(parseInt(itm.id)));

        setUpdateStatusData((prev) => {
          return { ...prev, assign_staff: val, staffs_id: staff_id };
        });
        setErrorText((prev) => ({ ...prev, staff: "" }));
      }
    } else {
      setUpdateStatusData((prev) => {
        return { ...prev, [name]: value };
      });
      setErrorText((prev) => ({ ...prev, [name]: "", comment: "" }));
    }
  };

  var submit = reportStatus.find((itm) => itm.status.key == "submitted");
  var invest = reportStatus.find((itm) => itm.status.key == "investigating");
  var completed = reportStatus.find((itm) => itm.status.key == "completed");
  var cancelled = reportStatus.find((itm) => itm.status.key == "cancelled");

  const valid = () => {
    let isValid = true;
    if (updateStatusData?.status == ("" || "label")) {
      setErrorText((prev) => ({ ...prev, status: "error" }));
      isValid = false;
    }
    if (updateStatusData?.status == 4) {
      if (updateStatusData?.comment == "") {
        setErrorText((prev) => ({ ...prev, comment: "error" }));
        isValid = false;
      }
    }
    return isValid;
  };

  const continueStatus = () => {
    if (valid()) {
      setUpdateTabValue(2);
    }
  };

  const confirmUpdateStatusData = () => {
    if (
      updateStatusData?.status == 2 &&
      updateStatusData?.staffs_id?.length > 0
    ) {
      assign_Staff();
    } else {
      changeStatus();
    }
  };

  const changeStatus = async (val) => {
    let data;
    if (val == "cancel") {
      data = {
        status_id: "4",
        comment: updateStatusData?.comment,
        images: uploadImage[0],
      };
    } else {
      data = new FormData();
      data.append("status_id", updateStatusData?.status);
      data.append("comment", updateStatusData?.comment);
      uploadImage.forEach((image) => {
        data.append("images[]", image);
      });
    }
    // )
    SetPostLoader(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}fault_report/property/${propertyId}/report/${id}/change_status`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      closeModal();
      getReportSingle();
      setpageloading(true);
      SetPostLoader(false);
    } catch (error) {
      console.log("err", error);
    }
  };

  const assign_Staff = async () => {
    SetPostLoader(true);
    const data = new FormData();
    data.append("status_id", updateStatusData?.status);
    data.append("comment", updateStatusData?.comment);
    updateStatusData?.staffs_id.forEach((id) => {
      data.append("assign_staff[]", id);
    });
    uploadImage.forEach((image) => {
      data.append("images[]", image);
    });
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}fault_report/property/${propertyId}/report/${id}/assign_staff`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      closeModal();
      getReportSingle();
      setpageloading(true);
      SetPostLoader(false);
    } catch (error) {
      console.log("err", error);
    }
  };

  const handleImage = () => {
    inputImg.current.click();
  };
  const handleDropImage = (e) => {
    e.preventDefault();
    const droppedImage = e.dataTransfer.files[0];
    if (droppedImage) {
      setUploadImage((prev) => [...prev, droppedImage]);
    }
  };
  const handleDragImage = (e) => {
    e.preventDefault();
  };

  const handleInputImage = (e) => {
    const droppedImage = e.target.files;
    if (droppedImage) {
      for (let i = 0; i < droppedImage.length; i++) {
        setUploadImage((prev) => [...prev, droppedImage[i]]);
      }
    }
  };

  const DeleteUploadedImage = (index) => {
    const data = uploadImage.filter((itm, ind) => ind !== index);
    setUploadImage(data);
  };

  useEffect(() => {
    const totalTimestamps = reporttrack.length;
    let totalDifference = 0;

    if (totalTimestamps <= 1) {
      setAverageResponse("-");
    } else {
      for (let i = 1; i < totalTimestamps; i++) {
        const timeDifference = Math.abs(
          new Date(reporttrack[i]?.created_at) -
          new Date(reporttrack[i - 1]?.created_at)
        );
        totalDifference += timeDifference;
      }
      const averageResponseTime = totalDifference / totalTimestamps;
      let displayValue;
      if (averageResponseTime < 60 * 60 * 1000) {
        const averageResponseMinutes = averageResponseTime / (1000 * 60);
        displayValue = `${Math.floor(averageResponseMinutes)} minutes`;
      } else {
        const averageResponseHours = averageResponseTime / (1000 * 60 * 60);
        displayValue = `${Math.floor(averageResponseHours)} hours`;
      }
      setAverageResponse(displayValue);
    }
  }, [selectedReport]);

  const duration = () => {
    if (cancelled || completed) {
      const hrs = Math.floor(
        (moment.utc(cancelled?.created_at || completed?.created_at).local()._d -
          moment(selectedReport?.reported_on)._d) /
        3600000
      );
      if (hrs == 0) {
        return `${Math.floor(
          ((moment.utc(cancelled?.created_at || completed?.created_at).local()
            ._d -
            moment(selectedReport?.reported_on)._d) %
            3600000) /
          60000
        )} mins`;
      } else {
        return `${Math.floor(
          (moment.utc(cancelled?.created_at || completed?.created_at).local()
            ._d -
            moment(selectedReport?.reported_on)._d) /
          3600000
        )} hrs ${Math.floor(
          ((moment.utc(cancelled?.created_at || completed?.created_at).local()
            ._d -
            moment(selectedReport?.reported_on)._d) %
            3600000) /
          60000
        )} mins`;
      }
    } else {
      return `${Math.floor(
        (moment(new Date())._d - moment(selectedReport?.reported_on)._d) /
        3600000
      )} hrs ${Math.floor(
        ((moment(new Date())._d - moment(selectedReport?.reported_on)._d) %
          3600000) /
        60000
      )} mins`;
    }
  };

  const DownloadReport = async () => {
    try {
      setLoadinReport(true);
      setDownloadAnchorEl(null);
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}fault_report/property/${propertyId}/report/${id}/download`,
        { responseType: "blob" }
      );
      saveAs(response.data, `Fault_report.pdf`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadinReport(false);
    }
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}

      {exitUpdateStatusModal && (
        <AlertModal
          modalDatas={exitUpdateStatusData}
          confirmFunction={() => {
            closeModal();
          }}
          closeFunction={() => {
            setexitUpdateStatusModal(false);
          }}
        />
      )}
      <BackArrow
        onClick={() => navigate(`/fault-reports`)}
        style={{ marginBottom: "16px", cursor: "pointer" }}
      />
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"column"}>
          {pageloading ? (
            <Skeleton height={30} />
          ) : (
            <PrimaryTypography>
              {LanguageData?.Review_Fault || "Fault Report"}{" "}
              {"#"+selectedReport?.id}
              <span style={{ marginLeft: "10px" }}>
                {getChip(selectedReport?.status_key)}
              </span>
            </PrimaryTypography>
          )}
          <StyledSecondaryTypography>
            {" "}
            {pageloading ? (
              <Skeleton width={200} />
            ) : (
              "Last Updated: " +
              moment
                .utc(selectedReport?.updated_at)
                .local()
                .format("DD MMM YYYY , HH:mm")
            )}{" "}
          </StyledSecondaryTypography>
        </Stack>
        {UserManage?.["fault-reports"] && !pageloading && (
          <Stack direction={"row"}>
            <Button
              sx={{
                maxHeight: "40px",
                fontWeight: "600",
                width: "fit-content",
                color: "#0E5E84",
                backgroundColor: "#E7EFF3",
                padding: "10px 16px 10px 16px",
                mr:3,
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#F9FAFB",
                  color: "#D2D6DB",
                },
              }}
              onClick={() => DownloadReport()}
              disabled={loadingReport}
            >
              {loadingReport ? (
                <Box sx={{ width: "40px", marginTop: "5px" }}>
                  <CircularProgress size={15} />
                </Box>
              ) : (
                <DownloadIcon style={{marginRight:'8px' , padding:'3px'}} />
              )}{" "}
              {LanguageData?.home_download_report || "Download Report"}
            </Button>
            <Button
              style={{
                padding:"10px 16px",
                borderRadius :"8px",
                backgroundColor:'#0E5E84',
                color:'#FFF',
                fontSize:'14px',
                fontWeight:600
              }}
              disabled={
                selectedReport?.status == 3 || selectedReport?.status == 4
                  ? true
                  : false
              }
              onClick={(e) => setUpdateAnchorEl(e.currentTarget)}
            >
              {LanguageData?.Update || "Update Status"}
            </Button>
          </Stack>
        )}
      </Stack>
      {pageloading ? (
        <Skeleton height={150} width="100%" />
      ) : (
        <Stack
          sx={{
            border: "1.5px solid #E5E7EB",
            backgroundColor: "#FFF",
            borderRadius: "8px",
            my: 4,
          }}
          direction={"row"}
        >
          <Stack
            direction={"column"}
            justifyContent={"center"}
            sx={{ borderRight: "1px solid #E5E7EB", p: 2, width: "25%" }}
          >
            <StyledSecondaryTypography>
              {LanguageData?.Duration || "Duration"}{" "}
            </StyledSecondaryTypography>
            <StyledPrimaryTypography>{duration()}</StyledPrimaryTypography>
          </Stack>
          <Stack
            direction={"column"}
            justifyContent={"center"}
            sx={{ borderRight: "1px solid #E5E7EB", p: 2, width: "25%" }}
          >
            <StyledSecondaryTypography>
              {LanguageData?.Reported_On || "Reported On "}{" "}
            </StyledSecondaryTypography>
            <StyledPrimaryTypography>
              {moment(selectedReport?.reported_on).format(
                "DD MMM YYYY , HH:mm"
              )}
            </StyledPrimaryTypography>
          </Stack>{" "}
          <Stack
            direction={"column"}
            justifyContent={"center"}
            sx={{ borderRight: "1px solid #E5E7EB", p: 2, width: "25%" }}
          >
            <StyledSecondaryTypography>
              {" "}
              {LanguageData?.Closed_On || "Closed On"}{" "}
            </StyledSecondaryTypography>
            <StyledPrimaryTypography>
              {selectedReport?.status == 3 || selectedReport?.status == 4
                ? moment
                  .utc(reporttrack[0]?.created_at)
                  .local()
                  .format("DD MMM YYYY , HH:mm")
                : "-"}
            </StyledPrimaryTypography>
          </Stack>{" "}
          <Stack
            direction={"column"}
            justifyContent={"center"}
            sx={{ borderRight: "1px solid #E5E7EB", p: 2, width: "25%" }}
          >
            <StyledSecondaryTypography>
              {LanguageData?.Average_Response_Time || "Average Response Time"}
            </StyledSecondaryTypography>
            <StyledPrimaryTypography>{averageResponse}</StyledPrimaryTypography>
          </Stack>
        </Stack>
      )}
      <Grid container>
        {pageloading ? (
          <Skeleton
            variant="rounded"
            height={400}
            width="65.5%"
            sx={{ mt: 2 }}
          />
        ) : (
          <Grid
            item
            xs={8}
            sx={{
              backgroundColor: "#FFF",
              borderRadius: "8px",
              border: "1px solid #E5E7EB",
            }}
          >
            <Box sx={{ borderBottom: "1px solid #E5E7EB" }}>
              <Tabs
                sx={{ width: "100%" }}
                value={tabValue}
                onChange={handleTabValue}
              >
                <Tab sx={{ width: "50%", maxWidth: "none" }} label="Details" />
                <Tab sx={{ width: "50%", maxWidth: "none" }} label="Comments" />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
              {selectedReport?.assigned_staff?.length > 0 && (
                <>
                  <Box
                    sx={{
                      backgroundColor: "#FFF",
                      borderTop: "1px solid #E5E7EB",
                      borderBottom: "1px solid #E5E7EB",
                      p: 2,
                    }}
                  >
                    <SecondaryTypography sx={{ fontSize: "18px !important" }}>
                      {LanguageData?.Assigned_Staff || "Assigned Staff"}
                    </SecondaryTypography>
                  </Box>
                  {selectedReport?.assigned_staff?.map((itm) => {
                    return (
                      <Grid container sx={{ p: 2 }}>
                        <Grid item xs={6}>
                          <Stack direction={"row"}>
                            <Avatar {...stringAvatar(itm?.full_name)} />
                            <Stack sx={{ ml: 2 }} direction={"column"}>
                              <StyledTypography>
                                {itm?.full_name}
                              </StyledTypography>
                              <StyledSecondaryTypography>
                                {itm?.department?.name} • {itm?.role?.name}
                              </StyledSecondaryTypography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={6}>
                          <Stack direction={"column"}>
                            <StyledSecondaryTypography>
                              {itm?.country_code}
                              {itm?.phone}
                            </StyledSecondaryTypography>
                            <StyledSecondaryTypography>
                              {itm?.email}
                            </StyledSecondaryTypography>
                          </Stack>
                        </Grid>
                      </Grid>
                    );
                  })}
                </>
              )}
              <Box
                sx={{
                  backgroundColor: "#FFF",
                  borderTop: "1px solid #E5E7EB",
                  borderBottom: "1px solid #E5E7EB",
                  p: 2,
                }}
              >
                <SecondaryTypography sx={{ fontSize: "18px !important" }}>
                  {LanguageData?.Report_Details || "Report Details"}
                </SecondaryTypography>
              </Box>
              <Grid sx={{ p: 2 }} container rowSpacing={3}>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {" "}
                    {LanguageData?.Announcement_tab_title || "Title"}{" "}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.4}>
                    {selectedReport?.report_details?.title}
                  </TypographyDefault>
                </Grid>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Location || "Location"}
                  </StyledSecondaryTypography>
                  <Typography mt={0.4} sx={{ fontSize: "14px" }}>
                    {selectedReport?.report_details?.location?.location_type}{" "}
                  </Typography>
                  <TypographyDefault>
                    {selectedReport?.report_details?.location?.building
                      ?.building_name
                      ? selectedReport?.report_details?.location?.building
                        ?.building_name +
                      " , " +
                      selectedReport?.report_details?.location?.building_level
                        ?.level
                      : ""}{" "}
                  </TypographyDefault>
                  <TypographyDefault>
                    {selectedReport?.report_details?.facility}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "15px",
                  }}
                />
                <Grid item xs={12}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Description || "Description"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.4}>
                    {selectedReport?.report_details?.description}
                  </TypographyDefault>
                </Grid>
                {/* {Boolean(invest) &&
                                <Grid item xs={12}>
                                    <StyledSecondaryTypography>Appointment</StyledSecondaryTypography>
                                    <TypographyDefault >{moment(invest?.created_at).format("DD MMM YYYY  • HH:mm")}</TypographyDefault>
                                </Grid>
                              } */}
              </Grid>

              <Box
                sx={{
                  backgroundColor: "#FFF",
                  borderTop: "1px solid #E5E7EB",
                  borderBottom: "1px solid #E5E7EB",
                  p: 2,
                }}
              >
                <SecondaryTypography sx={{ fontSize: "18px !important" }}>
                  {selectedReport?.IsReportedBystaff == null
                    ? "Resident Details"
                    : "Staff Details"}
                </SecondaryTypography>
              </Box>
              <Grid sx={{ p: 2 }} container rowSpacing={3}>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Reported_By || "Reported By"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.4}>
                    {selectedReport?.reported_by_details?.reported_by}
                  </TypographyDefault>
                </Grid>
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Email_Address || "Email Address"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.4}>
                    {selectedReport?.reported_by_details?.email}
                  </TypographyDefault>
                </Grid>
                <Divider
                  sx={{
                    borderTop: "1px solid #F3F4F6",
                    width: "100%",
                    marginTop: "15px",
                  }}
                />
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {LanguageData?.Contact_Number || "Contact Number"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.4}>
                    {selectedReport?.reported_by_details?.contact}
                  </TypographyDefault>
                </Grid>{" "}
                <Grid item xs={6}>
                  <StyledSecondaryTypography>
                    {selectedReport?.IsReportedBystaff == null
                      ? "Unit"
                      : "Department and Roles"}
                  </StyledSecondaryTypography>
                  <TypographyDefault mt={0.4}>
                    {" "}
                    {selectedReport?.IsReportedBystaff == null
                      ? selectedReport?.reported_by_details?.unit
                      : selectedReport?.reported_by_details?.dep_role}
                  </TypographyDefault>
                </Grid>
              </Grid>
              {selectedReport?.images?.length > 0 && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: "#FFF",
                      borderTop: "1px solid #E5E7EB",
                      borderBottom: "1px solid #E5E7EB",
                      px: 2,
                      height: "60px",
                    }}
                  >
                    <SecondaryTypography sx={{ fontSize: "18px !important" }}>
                      {LanguageData?.Supporting_Images || "Supporting Images"}
                    </SecondaryTypography>
                    <Button
                      disabled={loadingMultiple}
                      onClick={downloadMultipleImage}
                      variant="outlined"
                      sx={{
                        fontWeight: "600",
                        width: "fit-content",
                        color: "#0E5E84",
                        backgroundColor: "#FFF",
                        paddingRight: "20px !important",
                        mr: 3,
                      }}
                    >
                      {loadingMultiple ? (
                        <Box sx={{ width: "40px", marginTop: "5px" }}>
                          <CircularProgress size={15} />
                        </Box>
                      ) : (
                        <DownloadIcon width={40} />
                      )}
                      {LanguageData?.Download_All || "Download All"}
                    </Button>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    {selectedReport?.images?.map((itm) => {
                      return (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "1px solid #EAECF0",
                              borderRadius: "6px",
                              p: 2,
                              mt: 1.5,
                            }}
                          >
                            <Stack direction="row">
                              <JPGIcon style={{ marginRight: "12px" }} />
                              <Stack direction="column">
                                <StyledSecondaryTypography>
                                  {itm?.name}
                                </StyledSecondaryTypography>
                                <StyledSecondaryTypography>
                                  {(itm?.size / 1048576).toFixed(2)} MB
                                </StyledSecondaryTypography>
                              </Stack>
                            </Stack>
                            {/* <a
                                                    href="https://lobybe.demopsts.com/1698906439_pexels-saurabh-narwade-7254366.jpg"
                                                    target='_blank'
                                                    download
                                                    onClick={() => downloadImage()}
                                                > */}
                            {loadingMap[itm.path] ? (
                              <Box sx={{ width: "40px" }}>
                                <CircularProgress size={20} />
                              </Box>
                            ) : (
                              <DownloadIcon
                                onClick={() => downloadImage(itm.path)}
                                cursor={"pointer"}
                                width={40}
                              />
                            )}

                            {/* </a> */}
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <Box sx={{ p: 2 }}>
                {reportStatus?.map((itm) => {
                  const { created_at, comment, status, report_track_images } =
                    itm;
                  const { full_name, unit_details, role, department } =
                    itm.updated_user;
                  if (Boolean(comment) || report_track_images.length > 0) {
                    return (
                      <Card sx={{ mt: "10px" }}>
                        <CardHeader
                          avatar={
                            // <Avatar sx={{ backgroundColor: 'orange', color: 'orangered', mr: 2, fontSize: '12px' }}>OR</Avatar>
                            <Avatar {...stringAvatar(full_name)} />
                          }
                          action={getChip(status?.key)}
                          title={
                            <StyledTypography>{full_name}</StyledTypography>
                          }
                          subheader={
                            <StyledSecondaryTypography>
                              {role == null
                                ? `Management`
                                : `${department?.name} • ${role?.name}`}
                            </StyledSecondaryTypography>
                          }
                        />
                        <CardContent>
                          <TypographyDefault>{comment}</TypographyDefault>
                          {Boolean(report_track_images) && (
                            <Box
                              mt={2}
                              display="flex"
                              flexWrap="wrap"
                              gap="10px"
                            >
                              {report_track_images.map((itm) => (
                                <img
                                  src={`${process.env.REACT_APP_FILE_PATH}${itm.path}`}
                                  width="80"
                                  height="80"
                                  style={{ borderRadius: "8px" }}
                                />
                              ))}
                            </Box>
                          )}
                          <StyledSecondaryTypography sx={{ mt: 2 }}>
                            {moment
                              .utc(created_at)
                              .local()
                              .format("DD MMM YYYY , HH:mm")}
                          </StyledSecondaryTypography>
                        </CardContent>
                      </Card>
                    );
                  }
                })}
              </Box>
            </CustomTabPanel>
          </Grid>
        )}
        <Grid item xs={0.5} />
        {pageloading ? (
          <Skeleton variant="rounded" height={400} width="30%" sx={{ mt: 2 }} />
        ) : (
          <Grid item xs={3.5}>
            <StatusStepper status={reporttrack} />
          </Grid>
        )}
      </Grid>

      {/* UpdateStatus */}

      <Modal open={openModal}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFF",
              border: "1px solid #E5E7EB",
              borderRadius: "6px",
              width: { sm: "70%", md: "60%", lg: "35%" },
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {" "}
                {LanguageData?.Update || "Update Status"}{" "}
              </PrimaryTypography>
              <Close style={{ cursor: "pointer" }} onClick={closeModal} />
            </Box>
            <Box sx={{ borderBottom: "1px solid #E5E7EB" }}>
              <Tabs value={updateTabValue} onChange={handleUpdateTabValue}>
                <Tab sx={{ width: "50%" }} value={1} label="Update & Assign" />
                <Tab sx={{ width: "50%" }} value={2} label="Add Images" />
              </Tabs>
            </Box>
            {updateTabValue == 1 && (
              <Box value={updateTabValue} index={0}>
                <Box sx={{ p: 2 }}>
                  <StyledTypography>
                    {" "}
                    {LanguageData?.Update_to || "Update status to"}{" "}
                  </StyledTypography>
                  <FormControl fullWidth>
                    <Select
                      name="status"
                      value={updateStatusData.status}
                      sx={{
                        color: Boolean(errorText.status)
                          ? "#912018"
                          : updateStatusData.status == "label"
                            ? "#9DA4AE"
                            : "",
                        backgroundColor: Boolean(errorText.status)
                          ? "#FEE4E2"
                          : "#F9FAFB",
                      }}
                      onChange={(e) => {
                        handleChange(e, "");
                      }}
                    >
                      <MenuItem disabled value="label">
                        {LanguageData?.Please_select ||
                          "Please select a status"}
                      </MenuItem>
                      {selectedReport?.status == 1 && (
                        <MenuItem value={2}>
                          {getChip("investigating")}
                        </MenuItem>
                      )}
                      {selectedReport?.status == 2 && (
                        <MenuItem value={3}>{getChip("completed")}</MenuItem>
                      )}
                      {selectedReport?.status == 2 && (
                        <MenuItem value={4}>{getChip("cancelled")}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Box>
                {updateStatusData?.status != 3 &&
                  updateStatusData?.status != 4 && (
                    <Box sx={{ p: 2 }}>
                      <StyledTypography>
                        {" "}
                        {LanguageData?.Assign_to || "Assign to"}{" "}
                      </StyledTypography>
                      <Autocomplete
                        multiple
                        options={staffList}
                        name="assign_staff"
                        value={updateStatusData?.assign_staff}
                        onChange={(e, value) => handleChange(e, value)}
                        getOptionLabel={(option) => {
                          return (
                            <Stack direction="row" alignItems="center">
                              <Avatar {...stringAvatar(option?.full_name)} />
                              <Stack ml={2}>
                                <Typography>{option?.full_name}</Typography>
                                <Typography>
                                  {option?.department} • {option?.role_name}{" "}
                                </Typography>
                              </Stack>
                            </Stack>
                          );
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              sx={{
                                borderRadius: "5px",
                                "& .MuiAvatar-root": { color: "white" },
                              }}
                              avatar={
                                <Avatar {...stringAvatar(option?.full_name)} />
                              }
                              variant="outlined"
                              label={option?.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            placeholder="You can add multiple staff here"
                          />
                        )}
                      />
                      {/* <TextField
                                            name='assign_staff'
                                            placeholder="You can add multiple staff here"
                                            InputProps={{ disableUnderline: true }}
                                            value={updateStatusData.assign_staff}
                                            onChange={handleChange}
                                        /> */}
                    </Box>
                  )}
                <Box sx={{ p: 2 }}>
                  <StyledTypography>
                    {" "}
                    {LanguageData?.comment || "Comment"}{" "}
                  </StyledTypography>
                  <TextField
                    sx={{
                      minHeight: "100px",
                      backgroundColor: Boolean(errorText.comment)
                        ? "#FEE4E2"
                        : "#F9FAFB",
                    }}
                    name="comment"
                    value={updateStatusData.comment}
                    placeholder={
                      updateStatusData?.status == 4
                        ? "Please provide a reason for cancellation of case. (This comment is visible to all users)"
                        : updateStatusData?.status == 3
                          ? "Please enter any comments for the case. The case will considered closed after confirmation. (This comment is visible to all users)"
                          : "Please enter any comments or instructions for the case."
                    }
                    multiline
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => {
                      handleChange(e, "");
                    }}
                    inputProps={{
                      sx: {
                        "&::placeholder": {
                          color: Boolean(errorText.comment) ? "#912018" : "",
                        },
                      },
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={() => {
                      setexitUpdateStatusModal(true);
                    }}
                    variant="outlined"
                  >
                    {LanguageData?.Announcement_Cancel || "Cancel"}
                  </Button>
                  <Button
                     style={{
                      padding:"10px 16px",
                      borderRadius :"8px",
                      backgroundColor:'#0E5E84',
                      color:'#FFF',
                      fontSize:'14px',
                      fontWeight:600
                    }}
                    onClick={() => {
                      continueStatus();
                    }}
                  >
                    {LanguageData?.Common_Continue || "Continue"}
                  </Button>
                </Box>
              </Box>
            )}
            {updateTabValue == 2 && (
              <Box>
                <Box
                  sx={{
                    p: 1,
                    borderBottom: "1px solid #E5E7EB",
                    maxHeight: "400px !important",
                    overflowY: "scroll",
                    "::-webkit-scrollbar": { display: "none" },
                  }}
                >
                  <Grid
                    container
                    xs={12}
                    gap="2.8%"
                    sx={{ backgroundColor: "white", padding: "16px" }}
                  >
                    <Grid xs={12}>
                      {uploadImage.map((itm, index) => {
                        return (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                border: "1px solid #EAECF0",
                                borderRadius: "6px",
                                p: 2,
                                mt: 1.5,
                              }}
                            >
                              <Stack direction="row">
                                <JPGIcon style={{ marginRight: "12px" }} />
                                <Stack direction="column">
                                  <StyledSecondaryTypography>
                                    {itm?.name}
                                  </StyledSecondaryTypography>
                                  <StyledSecondaryTypography>
                                    {(itm?.size / 1048576).toFixed(2)} MB
                                  </StyledSecondaryTypography>
                                </Stack>
                              </Stack>
                              <DeleteIcon
                                onClick={() => {
                                  DeleteUploadedImage(index);
                                }}
                                width={40}
                              />
                            </Box>
                          </>
                        );
                      })}
                    </Grid>
                    {uploadImage.length < 1 && (<Grid xs={12}>
                      <Stack
                        onDragOver={handleDragImage}
                        onDrop={handleDropImage}
                        my="20px"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          padding: "30px",
                          border: "1px solid #EAECF0",
                          borderRadius: "12px",
                          cursor: "pointer"
                        }}
                      >
                        <Stack flex={1} direction="column" alignItems="center">
                          <UploadIcon onClick={handleImage} />
                          <input
                            type="file"
                            accept=".jpg,.jpeg,.png,.svg,.gif"
                            ref={inputImg}
                            onChange={handleInputImage}
                            multiple
                            hidden
                          />
                          <Stack onClick={handleImage} my="5px" direction="row">
                            <Typography
                              marginRight="5px"
                              sx={{
                                fontSize: "14px !important",
                                fontWeight: "600 !important",
                                color: "#0E4965 !important",
                              }}
                            >
                              {LanguageData?.Click || "Click to upload"}
                            </Typography>
                            <StyledSecondaryTypography
                              sx={{ color: "#4D5761" }}
                            >
                              {LanguageData?.Drag || "or drag and drop"}
                            </StyledSecondaryTypography>
                          </Stack>
                          <StyledSecondaryTypography sx={{ color: "#4D5761" }}>
                            {LanguageData?.File_type ||
                              "JPG, PNG (max. 25 megabytes)"}
                          </StyledSecondaryTypography>
                        </Stack>
                      </Stack>
                    </Grid>)}
                  </Grid>
                </Box>
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={() => {
                      setexitUpdateStatusModal(true);
                    }}
                    variant="outlined"
                  >
                    {LanguageData?.Announcement_Cancel || "Cancel"}
                  </Button>
                  <Button
                     style={{
                      padding:"10px 16px",
                      borderRadius :"8px",
                      backgroundColor:'#0E5E84',
                      color:'#FFF',
                      fontSize:'14px',
                      fontWeight:600
                    }}
                    onClick={() => {
                      setconfirmUpdateStatusModal(true);
                    }}
                  >
                    {LanguageData?.Common_Confirm || "Confirm"}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
      <Modal open={confirmUpdateStatusModal}>
        <Box
          sx={{
            position: "absolute",
            border: "none",
            width: "100%",
            height: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              width: "40%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                p: 2,
                borderBottom: "2px solid #F3F4F6",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PrimaryTypography>
                {LanguageData?.Confirm_to || "Confirm to change the status"}
              </PrimaryTypography>
              <Close onClick={() => setconfirmUpdateStatusModal(false)} />
            </Box>
            <Box sx={{ px: 2, py: 3, borderBottom: "2px solid #F3F4F6" }}>
              <TypographyDefault>
                {updateStatusData?.status == 2 &&
                  'Please confirm if you wish to change the statusfrom "Submitted" to "Investigating".'}
                {updateStatusData?.status == 3 &&
                  'Please confirm if you wish to change the statusfrom "Investigating" to "Completed".'}
                {updateStatusData?.status == 4 &&
                  'Please confirm if you wish to change the status from "Investigating" to "Cancelled". Once you update the status to "Cancelled", you will no longer be able to edit any information in the case.".'}
              </TypographyDefault>

              <Box sx={{ mt: 3 }} display="flex" alignItems="center">
                {getChip(selectedReport?.status_key)}
                <Box display="flex" alignItems="center">
                  <div
                    style={{
                      marginLeft: "6px",
                      borderBottom: "1px solid black",
                      width: "50px",
                      height: "1px",
                    }}
                  ></div>
                  <span style={{ marginRight: "6px" }}>{">"}</span>
                </Box>
                {getChip(updateStatusData?.status == 3 && "completed") ||
                  getChip(updateStatusData?.status == 4 && "cancelled") ||
                  getChip(updateStatusData?.status == 2 && "investigating")}
              </Box>
            </Box>
            <Box
              sx={{ p: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Button
                onClick={() => setconfirmUpdateStatusModal(false)}
                sx={{ background: "#FFFFFF", color: "#0E5E84" }}
                variant="outlined"
              >
                {LanguageData?.Announcement_Cancel || "Cancel"}
              </Button>
              <Button
                onClick={() => confirmUpdateStatusData()}
                sx={{
                  background: "#B42318",
                  color: "#FFFFFF",
                  "&:hover": { background: "#B42318" },
                }}
                variant="contained"
              >
                {LanguageData?.Common_Confirm || "Confirm"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Menu
        sx={{ m: 0.5 }}
        open={updateOpen}
        anchorEl={updateAnchorEl}
        onClose={() => setUpdateAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setOpenModal(true);
            setUpdateAnchorEl(null);
          }}
        >
          <styledTyphographyDefault>
            {LanguageData?.Update_Assgin || "Update, Assign & Comment"}
          </styledTyphographyDefault>
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeStatus("cancel");
            setUpdateAnchorEl(null);
          }}
        >
          <styledTyphographyDefault>
            {LanguageData?.Cancel_Case || "Cancel Case"}{" "}
          </styledTyphographyDefault>
        </MenuItem>
      </Menu>
      <Menu
        sx={{ m: 0.5 }}
        open={downloadOpen}
        anchorEl={downloadAnchorEl}
        onClose={() => setDownloadAnchorEl(null)}
      >
        <MenuItem>
          <TypographyDefault
            py={0.5}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <CsvIcon width={40} />{" "}
            {LanguageData?.Download_csv || "Download as CSV file"}
          </TypographyDefault>
        </MenuItem>
        <MenuItem>
          <TypographyDefault
            py={0.5}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <PdfIcon width={40} />
            {LanguageData?.Download_pdf || "Download as PDF file"}
          </TypographyDefault>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ReviewFaultReport;
